module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-fresnel-virtual-f22638ff09/1/packages/gatsby-plugin-fresnel/gatsby-browser.js'),
      options: {"plugins":[],"breakpoints":{"mobile":0,"tablet":768,"computer":1024}},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-7a81ed2e48/0/cache/gatsby-plugin-google-gtag-npm-5.14.0-7fc489a55d-930de28fb2.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Z6RESBQTGW"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-3ed3bd49b8/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
