"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.MediaContextProvider = exports.Media = exports.mediaStyles = void 0;

var _fresnel = require("@artsy/fresnel");

var _config = _interopRequireDefault(require("gatsby-plugin-fresnel/config.json"));

const AppMedia = (0, _fresnel.createMedia)(_config.default); // Make styles for injection into the header of the page

const mediaStyles = AppMedia.createMediaStyle();
exports.mediaStyles = mediaStyles;
const {
  Media,
  MediaContextProvider
} = AppMedia;
exports.MediaContextProvider = MediaContextProvider;
exports.Media = Media;